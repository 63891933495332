<script setup lang="ts">
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';

const router = useRouter();
const route = useRoute();

const isComparePage = computed(() => {
  if (route.name === 'alternative') {
    return true;
  }
  return false;
});

const toggle = ref(false);
const toggleSearchBar = ref(false);
const searchText = ref('');

const openSolutionsDisclosure = ref(false);
const openResourcesDisclosure = ref(false);

const toggleSearch = () => {
  toggleSearchBar.value = !toggleSearchBar.value;
};

const toggleMenu = () => {
  toggle.value = !toggle.value;
  const menuBtn = document.querySelector('.menu-btn') as Element;
  menuBtn?.classList.toggle('active');
  if (toggleSearchBar.value) {
    toggleSearchBar.value = false;
  }
};

async function onSubmitSearchForm() {
  if (!searchText.value) {
    return;
  }

  await router.push({
    path: 'search-results',
    query: {
      q: searchText.value.trim(),
    },
  });
  toggleSearchBar.value = false;
}

if (process.client) {
  (document as any).getElementById('searchbar').addEventListener('keypress', async function (event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
      await onSubmitSearchForm();
    }
  });
}

function onSolutionsDisclosureClick() {
  openResourcesDisclosure.value = false;
  openSolutionsDisclosure.value = !openSolutionsDisclosure.value;
}

function closeSolutionsDisclosure() {
  openSolutionsDisclosure.value = false;
}

function onResourcesDisclosureClick() {
  openSolutionsDisclosure.value = false;
  openResourcesDisclosure.value = !openResourcesDisclosure.value;
}

function closeResourcesDisclosure() {
  openResourcesDisclosure.value = false;
}

onMounted(() => {
  document.addEventListener(
    'click',
    (e: Event) => {
      const target = e.target as HTMLElement;
      const targetId = target?.id;
      if (
        targetId === 'disclosure-btn-span-solutions' ||
        targetId === 'disclosure-btn-icon-solutions' ||
        targetId === 'disclosure-btn-span-solutions-mobile' ||
        targetId === 'disclosure-btn-icon-solutions-mobile' ||
        targetId === 'disclosure-btn-span-resources' ||
        targetId === 'disclosure-btn-icon-resources' ||
        targetId === 'disclosure-btn-span-resources-mobile' ||
        targetId === 'disclosure-btn-icon-resources-mobile'
      )
        return;

      closeSolutionsDisclosure();
      closeResourcesDisclosure();
    },
    true,
  );
});
</script>
<template>
  <header class="">
    <div class="bg-[#0070C0]">
      <div class="container mx-auto">
        <div
          @click="
            () => {
              if (toggle) toggleMenu();
            }
          "
          class="relative flex justify-center overflow-x-hidden p-2"
        >
          <span
            class="animate-marquee whitespace-nowrap text-center text-xs font-bold text-[#fff] md:text-lg lg:animate-none lg:whitespace-normal"
            >Make Your Customer Engagement Better With ZapScale Campaigns.
            <NuxtLink target="_blank" to="https://www.zapscale.com/blog/customer-email-campaign" class="underline">
              Read More
            </NuxtLink>
          </span>
        </div>
      </div>
    </div>

    <div class="bg-[#242424]">
      <div class="container mx-auto">
        <div class="px-4 md:py-1">
          <nav id="header" class="flex flex-row items-center justify-between md:py-3.5">
            <NuxtLink to="/">
              <NuxtImg
                width="210"
                height="17"
                sizes=""
                class="md:w-[150px] xl:w-[175px]"
                src="/images/header/zapscale-logo-header.webp"
                alt="ZapScale"
              />
            </NuxtLink>
            <div class="flex flex-row items-center justify-between gap-x-4">
              <form id="form" v-show="!isComparePage" class="hidden flex-row items-center gap-x-2 lg:flex">
                <input
                  v-model="searchText"
                  class="left-auto right-12 w-0 rounded-full bg-[#242424] px-3 py-1 text-white outline-none duration-500 focus:w-[12vw] focus:border-2 focus:border-white lg:h-7 lg:placeholder:text-sm xl:h-9 xl:placeholder:text-base"
                  id="searchbar"
                  type="search"
                  name="q"
                  placeholder="Search"
                />
                <label for="searchbar">
                  <span class="inline-block rotate-45 cursor-pointer text-2xl text-white xl:text-3xl"> &#9906; </span>
                </label>
              </form>
              <ul class="hidden items-center gap-x-4 font-medium text-white lg:flex lg:text-base xl:text-lg">
                <li
                  @click="
                    () => {
                      closeSolutionsDisclosure();
                      closeResourcesDisclosure();
                    }
                  "
                >
                  <NuxtLink to="/">Home</NuxtLink>
                </li>
                <li
                  v-if="isComparePage"
                  @click="
                    () => {
                      closeSolutionsDisclosure();
                      closeResourcesDisclosure();
                    }
                  "
                >
                  <NuxtLink to="#compare-section">Compare</NuxtLink>
                </li>
                <li
                  @click="
                    () => {
                      closeSolutionsDisclosure();
                      closeResourcesDisclosure();
                    }
                  "
                >
                  <NuxtLink v-if="!isComparePage" to="/features">Features</NuxtLink>
                  <NuxtLink v-else to="#features-section">Features</NuxtLink>
                </li>
                <li v-show="!isComparePage">
                  <Disclosure>
                    <DisclosureButton class="flex items-center gap-x-2">
                      <span @click="onSolutionsDisclosureClick" id="disclosure-btn-span-solutions"> Solutions </span>
                      <Icon
                        id="disclosure-btn-icon-solutions"
                        name="ep:arrow-down-bold"
                        @click="onSolutionsDisclosureClick"
                        class="max-xl:w-3"
                        :class="openSolutionsDisclosure ? 'rotate-180 transform' : ''"
                      />
                    </DisclosureButton>
                    <div v-if="openSolutionsDisclosure">
                      <DisclosurePanel
                        static
                        class="absolute mt-5 flex w-[460px] gap-x-8 rounded-xl bg-[#F5F3F3] px-4 py-4 text-[#242424] shadow"
                      >
                        <div>
                          <span class="text-[15px] font-bold text-[#242424]">BY ROLE</span>
                          <ul class="mt-2 divide-y-[1px] text-base font-medium">
                            <li
                              @click="
                                () => {
                                  closeSolutionsDisclosure();
                                  closeResourcesDisclosure();
                                }
                              "
                              class="cursor-pointer px-1 py-2 hover:rounded-md hover:bg-gray-200"
                            >
                              <NuxtLink class="inline-block w-full" to="/solutions/customer-success-managers"
                                >Customer Success Managers</NuxtLink
                              >
                            </li>
                            <li
                              @click="
                                () => {
                                  closeSolutionsDisclosure();
                                  closeResourcesDisclosure();
                                }
                              "
                              class="cursor-pointer px-1 py-2 hover:rounded-md hover:bg-gray-200"
                            >
                              <NuxtLink class="inline-block w-full" to="/solutions/customer-success-heads"
                                >Customer Success Leaders</NuxtLink
                              >
                            </li>
                            <li
                              @click="
                                () => {
                                  closeSolutionsDisclosure();
                                  closeResourcesDisclosure();
                                }
                              "
                              class="cursor-pointer px-1 py-2 hover:rounded-md hover:bg-gray-200"
                            >
                              <NuxtLink class="inline-block w-full" to="/solutions/founders-and-cxos"
                                >Founders and CXOs</NuxtLink
                              >
                            </li>
                          </ul>
                        </div>
                        <div>
                          <span class="text-[15px] font-bold text-[#242424]">BY CHALLENGE</span>
                          <ul class="mt-2 divide-y-[1px] text-base font-medium">
                            <li
                              @click="
                                () => {
                                  closeSolutionsDisclosure();
                                  closeResourcesDisclosure();
                                }
                              "
                              class="cursor-pointer px-1 py-2 hover:rounded-md hover:bg-gray-200"
                            >
                              <NuxtLink class="inline-block w-full" to="/solutions/customer-churn"
                                >Customer Churn</NuxtLink
                              >
                            </li>
                            <li
                              @click="
                                () => {
                                  closeSolutionsDisclosure();
                                  closeResourcesDisclosure();
                                }
                              "
                              class="cursor-pointer px-1 py-2 hover:rounded-md hover:bg-gray-200"
                            >
                              <NuxtLink class="inline-block w-full" to="/solutions/customer-visibility"
                                >Customer Visibility</NuxtLink
                              >
                            </li>
                            <li
                              @click="
                                () => {
                                  closeSolutionsDisclosure();
                                  closeResourcesDisclosure();
                                }
                              "
                              class="cursor-pointer px-1 py-2 hover:rounded-md hover:bg-gray-200"
                            >
                              <NuxtLink class="inline-block w-full" to="/solutions/product-adoption"
                                >Product Adoption</NuxtLink
                              >
                            </li>
                          </ul>
                        </div>
                      </DisclosurePanel>
                    </div>
                  </Disclosure>
                </li>
                <li
                  @click="
                    () => {
                      closeSolutionsDisclosure();
                      closeResourcesDisclosure();
                    }
                  "
                >
                  <NuxtLink v-if="!isComparePage" to="/pricing">Pricing</NuxtLink>
                  <NuxtLink v-else to="#pricing-section">Pricing</NuxtLink>
                </li>
                <li
                  v-show="!isComparePage"
                  @click="
                    () => {
                      closeSolutionsDisclosure();
                      closeResourcesDisclosure();
                    }
                  "
                >
                  <NuxtLink to="/integrations">Integrations</NuxtLink>
                </li>
                <!-- <li @click="closeDisclosure()"><NuxtLink to="/blog">Blog</NuxtLink></li> -->
                <li v-show="!isComparePage">
                  <Disclosure>
                    <DisclosureButton class="flex items-center gap-x-2">
                      <span @click="onResourcesDisclosureClick" id="disclosure-btn-span-resources"> Resources </span>
                      <Icon
                        id="disclosure-btn-icon-resources"
                        name="ep:arrow-down-bold"
                        @click="onResourcesDisclosureClick"
                        class="max-xl:w-3"
                        :class="openResourcesDisclosure ? 'rotate-180 transform' : ''"
                      />
                    </DisclosureButton>
                    <div v-if="openResourcesDisclosure">
                      <DisclosurePanel
                        static
                        class="absolute mt-5 flex w-[150px] gap-x-8 rounded-xl bg-[#F5F3F3] px-4 py-4 text-[#242424] shadow"
                      >
                        <ul class="w-full divide-y-[1px] text-base font-medium">
                          <li
                            @click="closeResourcesDisclosure()"
                            class="cursor-pointer px-1 py-2 hover:rounded-md hover:bg-gray-200"
                          >
                            <NuxtLink class="inline-block w-full" to="/blog">Blog</NuxtLink>
                          </li>
                          <li
                            @click="closeResourcesDisclosure()"
                            class="cursor-pointer px-1 py-2 hover:rounded-md hover:bg-gray-200"
                          >
                            <NuxtLink class="inline-block w-full" to="/podcasts">Podcasts</NuxtLink>
                          </li>
                          <li
                            @click="closeResourcesDisclosure()"
                            class="cursor-pointer px-1 py-2 hover:rounded-md hover:bg-gray-200"
                          >
                            <NuxtLink class="inline-block w-full" to="/webinars">Webinars</NuxtLink>
                          </li>
                          <li
                            @click="closeResourcesDisclosure()"
                            class="cursor-pointer px-1 py-2 hover:rounded-md hover:bg-gray-200"
                          >
                            <NuxtLink class="inline-block w-full" to="/customer-success-glossary">Glossary</NuxtLink>
                          </li>
                        </ul>
                      </DisclosurePanel>
                    </div>
                  </Disclosure>
                </li>
                <li
                  @click="
                    () => {
                      closeSolutionsDisclosure();
                      closeResourcesDisclosure();
                    }
                  "
                >
                  <NuxtLink to="/demo/#book-a-demo">
                    <button class="rounded-full bg-[#FF395B] px-2 py-1 text-white xl:px-6 xl:py-2 xl:font-bold">
                      Book a Demo
                    </button>
                  </NuxtLink>
                </li>
              </ul>
            </div>

            <div class="flex flex-row items-center justify-end gap-x-3 lg:hidden">
              <button @click="toggleSearch" class="-rotate-45 text-3xl text-white focus:shadow-none focus:outline-none">
                <span id="search-btn">&#9906;</span>
              </button>

              <div
                id="menu-btn"
                class="menu-btn flex justify-end focus:shadow-none focus:outline-none"
                @click="toggleMenu"
              >
                <span class="block"></span>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>

    <div id="mobile-menu">
      <nav v-if="toggle" class="absolute h-fit w-full animate-open-menu bg-[#242424] py-6 lg:hidden">
        <ul class="flex flex-col gap-y-3 px-1 text-lg font-medium text-white md:text-lg">
          <li
            @click="
              () => {
                closeSolutionsDisclosure(), toggleMenu();
              }
            "
          >
            <NuxtLink to="/">
              <p class="w-full px-4 py-1">Home</p>
            </NuxtLink>
          </li>
          <li
            v-if="isComparePage"
            @click="
              () => {
                closeSolutionsDisclosure(), toggleMenu();
              }
            "
          >
            <NuxtLink to="#compare-section">
              <span class="w-full px-4 py-1">Compare</span>
            </NuxtLink>
          </li>
          <li
            @click="
              () => {
                closeSolutionsDisclosure(), toggleMenu();
              }
            "
          >
            <NuxtLink v-if="!isComparePage" to="/features">
              <p class="w-full px-4 py-1">Features</p>
            </NuxtLink>
            <NuxtLink v-else to="#features-section"><span class="w-full px-4 py-1">Features</span></NuxtLink>
          </li>
          <li v-show="!isComparePage" class="px-4">
            <Disclosure>
              <DisclosureButton class="flex items-center gap-x-2">
                <span @click="onSolutionsDisclosureClick" id="disclosure-btn-span-solutions-mobile">Solutions</span>
                <NuxtImg
                  @click="onSolutionsDisclosureClick"
                  id="disclosure-btn-icon-solutions-mobile"
                  src="/images/down-arrow-disclosure-white.svg"
                  alt="disclosure-icon"
                  class="h-3 w-3"
                  :class="openSolutionsDisclosure ? 'rotate-180 transform' : ''"
                />
              </DisclosureButton>
              <div v-show="openSolutionsDisclosure" class="animate-appear">
                <DisclosurePanel static class="flex flex-col gap-y-4 divide-y-[0.2px] px-3 py-3">
                  <div>
                    <span class="text-[15px] font-bold">BY ROLE</span>
                    <ul class="mt-2 text-base font-medium">
                      <li
                        @click="
                          () => {
                            closeSolutionsDisclosure(), toggleMenu();
                          }
                        "
                        class="pb-2"
                      >
                        <NuxtLink to="/solutions/customer-success-managers"
                          ><p class="pl-2">Customer Success Managers</p></NuxtLink
                        >
                      </li>
                      <li
                        @click="
                          () => {
                            closeSolutionsDisclosure(), toggleMenu();
                          }
                        "
                        class="py-2"
                      >
                        <NuxtLink to="/solutions/customer-success-heads"
                          ><p class="pl-2">Customer Success Leaders</p></NuxtLink
                        >
                      </li>
                      <li
                        @click="
                          () => {
                            closeSolutionsDisclosure(), toggleMenu();
                          }
                        "
                        class="pt-2"
                      >
                        <NuxtLink to="/solutions/founders-and-cxos"><p class="pl-2">Founders and CXOs</p></NuxtLink>
                      </li>
                    </ul>
                  </div>
                  <div class="pt-4">
                    <span class="text-[15px] font-bold">BY CHALLENGE</span>
                    <ul class="mt-2 text-base font-medium">
                      <li
                        @click="
                          () => {
                            closeSolutionsDisclosure(), toggleMenu();
                          }
                        "
                        class="pb-2"
                      >
                        <NuxtLink to="/solutions/customer-churn"><p class="pl-2">Customer Churn</p></NuxtLink>
                      </li>
                      <li
                        @click="
                          () => {
                            closeSolutionsDisclosure(), toggleMenu();
                          }
                        "
                        class="py-2"
                      >
                        <NuxtLink to="/solutions/customer-visibility"><p class="pl-2">Customer Visibility</p></NuxtLink>
                      </li>
                      <li
                        @click="
                          () => {
                            closeSolutionsDisclosure(), toggleMenu();
                          }
                        "
                        class="pt-2"
                      >
                        <NuxtLink to="/solutions/product-adoption"><p class="pl-2">Product Adoption</p></NuxtLink>
                      </li>
                    </ul>
                  </div>
                </DisclosurePanel>
              </div>
            </Disclosure>
          </li>
          <li
            @click="
              () => {
                closeSolutionsDisclosure(), toggleMenu();
              }
            "
          >
            <NuxtLink v-if="!isComparePage" to="/pricing">
              <p class="w-full px-4 py-1">Pricing</p>
            </NuxtLink>
            <NuxtLink v-else to="#pricing-section">
              <span class="w-full px-4 py-1">Pricing</span>
            </NuxtLink>
          </li>
          <li
            v-show="!isComparePage"
            @click="
              () => {
                closeSolutionsDisclosure(), toggleMenu();
              }
            "
          >
            <NuxtLink to="/integrations">
              <p class="w-full px-4 py-1">Integrations</p>
            </NuxtLink>
          </li>
          <li v-show="!isComparePage" class="px-4">
            <Disclosure>
              <DisclosureButton class="flex items-center gap-x-2">
                <span @click="onResourcesDisclosureClick" id="disclosure-btn-span-resources-mobile">Resources</span>
                <NuxtImg
                  @click="onResourcesDisclosureClick"
                  id="disclosure-btn-icon-resources-mobile"
                  src="/images/down-arrow-disclosure-white.svg"
                  alt="disclosure-icon"
                  class="h-3 w-3"
                  :class="openResourcesDisclosure ? 'rotate-180 transform' : ''"
                />
              </DisclosureButton>
              <div v-show="openResourcesDisclosure" class="animate-appear">
                <DisclosurePanel static class="flex flex-col gap-y-4 px-3 py-3">
                  <ul class="mt-2 text-base font-medium">
                    <li
                      @click="
                        () => {
                          closeSolutionsDisclosure(), toggleMenu();
                        }
                      "
                      class="pb-2"
                    >
                      <NuxtLink to="/blog"><p class="pl-2">Blog</p></NuxtLink>
                    </li>
                    <li
                      @click="
                        () => {
                          closeSolutionsDisclosure(), toggleMenu();
                        }
                      "
                      class="py-2"
                    >
                      <NuxtLink to="/podcasts"><p class="pl-2">Podcasts</p></NuxtLink>
                    </li>
                    <li
                      @click="
                        () => {
                          closeSolutionsDisclosure(), toggleMenu();
                        }
                      "
                      class="py-2"
                    >
                      <NuxtLink to="/webinars"><p class="pl-2">Webinars</p></NuxtLink>
                    </li>
                    <li
                      @click="
                        () => {
                          closeSolutionsDisclosure(), toggleMenu();
                        }
                      "
                      class="py-2"
                    >
                      <NuxtLink to="/customer-success-glossary"><p class="pl-2">Glossary</p></NuxtLink>
                    </li>
                  </ul>
                </DisclosurePanel>
              </div>
            </Disclosure>
          </li>
          <li
            @click="
              () => {
                closeSolutionsDisclosure(), toggleMenu();
              }
            "
          >
            <NuxtLink to="/demo/#book-a-demo">
              <p class="w-full px-4 py-1">Book a Demo</p>
            </NuxtLink>
          </li>
        </ul>
      </nav>
    </div>

    <div id="mobile-search">
      <nav v-if="toggleSearchBar" class="absolute w-full animate-open-menu bg-[#242424] py-6 lg:hidden">
        <form @submit.prevent="onSubmitSearchForm" class="flex flex-row items-center gap-x-4 px-4">
          <input
            v-model="searchText"
            class="right-12 w-[75vw] bg-[#f9f7f5] px-3 py-1 outline-none focus:outline"
            id="mobile-searchbar"
            type="search"
            name="q"
            placeholder="Search"
          />
          <button type="submit" class="rotate-45 text-2xl text-[#f9f7f5]">&#9906;</button>
        </form>
      </nav>
    </div>
  </header>
</template>

<style scoped lang="postcss">
.menu-btn {
  height: 32px;
  width: 40px;
  cursor: pointer;
}

.menu-btn span,
.menu-btn span::before,
.menu-btn span::after {
  background: white;
  border-radius: 3px;
  content: '';
  position: absolute;
  width: 23px;
  height: 4px;
  margin-top: 13px;

  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.menu-btn span::before {
  margin-top: -8px;
}

.menu-btn span::after {
  margin-top: 8px;
}

.menu-btn.active span {
  background: transparent;
}

.menu-btn.active span::before {
  margin-top: 0;

  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.menu-btn.active span::after {
  margin-top: 0;

  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.router-link-active > p {
  @apply bg-[#58585c];
  @apply rounded-md;
}

.shadow {
  box-shadow: 4px 12px 20px rgba(0, 0, 0, 0.3);
}
</style>
